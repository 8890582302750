.service_banner {
    width              : 100%;
    height             : 50rem;
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center center;
    overflow           : hidden;
}

.service_banner .container {
    height: 100%;
}

.service_banner .banner_inner {
    width          : inherit;
    height         : 100%;
    display        : flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 6.25rem;
}

.service_banner .banner_inner h1 {
    font-weight: 500;
    font-size: 7.5rem;
    line-height: 100%;
    color: var(--white);
    max-width: 700px;
}

@media (orientation: portrait) {

    .service_banner {
        height: 37.5rem;
    }

    .service_banner .banner_inner {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 2.5rem;
    }

    .service_banner .banner_inner h1 {
        font-size: 3.75rem;
    }
    
}