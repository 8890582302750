@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Extralight.otf');
    font-weight: 200;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-ExtralightItalic.otf');
    font-weight: 200;
    font-style : italic;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Light.otf');
    font-weight: 300;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-LightItalic.otf');
    font-weight: 300;
    font-style : italic;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Regular.otf');
    font-weight: 400;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Medium.otf');
    font-weight: 500;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-MediumItalic.otf');
    font-weight: 500;
    font-style : italic;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Semibold.otf');
    font-weight: 600;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-SemiboldItalic.otf');
    font-weight: 600;
    font-style : italic;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-Bold.otf');
    font-weight: 700;
    font-style : normal;
}

@font-face {
    font-family: 'General Sans';
    src        : url('./GeneralSans-BoldItalic.otf');
    font-weight: 700;
    font-style : italic;
}