.reports_gallery {
  padding-top: 200px;
  padding-bottom: 200px;
}
.reports_gallery h1 {
  color: #2b2f2c;
  font-size: 7.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding-bottom: 2rem;
}

.nm_reports {
  padding-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
}
.nm_reports_small {
  display: none;
}
@media (orientation: portrait) {
  .reports_gallery {
    padding-top: 5.5rem;
  }
  .reports_gallery h1 {
    color: #2b2f2c;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 100%;
  }
  .nm_reports {
    display: none;
  }
  .nm_reports_small {
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.875rem;
  }
}
