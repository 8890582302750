
.media_card {
    border-radius: 1.25rem;
    border       : 1px solid var(--black-1);
    background   : var(--white);
    overflow     : hidden;
}
.media_card_image {
    width: 24.875rem;
    height: 18.75rem;
    border-bottom: 1px solid var(--black-1);
}
.media_card_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.media_card:hover {
    cursor: pointer;
}

/* .media_card .thumb_img {
    width: 100%;
} */

.media_card .content {
    border-bottom: 1px solid var(--black-1);
    padding      : 1.25rem 1.5rem;
}

.media_card .date_tag {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 1rem;
}

.media_card h3 {
    height: 5.125rem;
    font-weight       : 500;
    font-size         : 1.25rem;
    line-height       : 1.6875rem;
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

.media_card p {
    height: 5.125rem;
    font-weight       : 500;
    font-size         : 1.25rem;
    line-height       : 1.6875rem;
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

.media_card a {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    color          : var(--black-1);
    padding        : 1.25rem 1.5rem;
    transition     : background-color .3s ease-in-out;
}

.media_card a:hover {
    background-color: var(--purple);
}

.media_card a span {
    font-weight: 500;
    transition : color .3s ease-in-out;
}

.media_card a:hover span {
    color: var(--white);
}

.media_card a img {
    width     : 1.5rem;
    height    : 1.5rem;
    transition: filter .3s ease-in-out;
}

.media_card a:hover img {
    filter: invert(1);
}


@media (orientation: portrait) {

    .media_card .content,
    .media_card a {
        padding: 1.25rem 1rem;
    }
}