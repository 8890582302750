.initiatives_card {
  padding-top: 200px;
  padding-bottom: 200px;
}
.initiatives_card h1 {
  color: #2b2f2c;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  padding-bottom: 2rem;
}

.initiatives_card h3 {
  height: 4rem;
  font-weight: 600;
  font-size: 24px;
  line-height: 32.4px;
}

.media_card span {
  font-weight: 500;
  font-size: 16px;
  line-height: 21.6px;
}

@media (orientation: portrait) {
  .initiatives_card {
    padding-top: 5.5rem;
  }
  .initiatives_card h1 {
    color: #2b2f2c;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 100%;
  }

  .media_card_image img {
    width: 95%;
    height: 95%;
    object-fit: cover;
  }
}
