.title_desc_img p {
    font-size: 1.5rem;
    line-height: 150%;
}

.title_desc_img .img_box {
    border-radius: 20px;
    margin-top: 3.125rem;
}

@media (orientation: portrait) {
    .title_desc_img p {
        font-size: 1rem;
        line-height: 150%;
    }

    .title_desc_img .img_box {
        margin-top: 2rem;
    }
}