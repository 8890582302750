.exhibitions {
    border-radius: 0px 0px 80px 80px;
    background   : var(--black-1);
}

.exhibitions .title_48 {
    color: var(--white);
}

.exhibition_slider_wrapper {
    position: relative;
}

.exhibition_slider_wrapper .swiper-pagination {
    display: none;
}

@media (orientation: portrait) {
    .exhibitions {
        border-radius: 0px 0px 20px 20px;
    }

    .exhibition_slider_wrapper {
        padding-bottom: 3rem;
    }

    .exhibition_slider_wrapper .swiper-pagination {
        display: block;
    }

    .exhib_card .img_box {
        min-height: 18.75rem;
    }

    .exhib_card .img_box,
    .exhib_card .content,
    .exhib_card a {
        padding: 1.5rem 1rem;
    }

    .exhib_card .content h5 {
        font-size: 1.25rem;
        margin   : .75rem 0 1rem;
    }
}