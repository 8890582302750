.testimonial2_sc .grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
}

.testimonial2_sc .grid_container .grid_item {
  border: 1px solid rgba(43, 47, 44, 0.2);
}
.testimonial2_sc .grid_container .logo_item {
  display: grid;
  place-items: center;
}

.testimonial2_sc .grid_container .grid_item:nth-child(1) {
  text-align: center;
}

.testimonial2_sc .grid_container .grid_item:nth-child(2) {
  padding: 3.125rem 3.75rem;
  grid-column: 2 / span 2;
}

.testimonial2_sc .org_logo {
  max-width: 200px;
}

.testimonial2_sc:not(.black) .org_logo {
  filter: invert(1);
}

.testimonial2_sc p {
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.375rem;
  margin-bottom: 3.125rem;
}

.testimonial2_sc h5 {
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.testimonial2_sc .swiper-button-prev {
  left: auto;
  right: 7.5rem;
}

.testimonial2_sc .swiper-button-next {
  right: 3.75rem;
}

.testimonial2_sc .swiper-button-next,
.testimonial2_sc .swiper-button-prev {
  top: auto !important;
  bottom: 3.125rem !important;
}

/* if backgound black */
.testimonial2_sc.black {
  background-color: var(--black-1);
  margin: 6.25rem 0;
}

.testimonial2_sc.black .grid_container .grid_item {
  border: 1px solid #2b2f2c;
}

/* .testimonial2_sc.black .org_logo {
    filter: invert(1);
} */

.testimonial2_sc.black p,
.testimonial2_sc.black h5,
.testimonial2_sc.black span {
  color: var(--white);
}

.testimonial2_sc.black .swiper-button-prev:after {
  background-image: url("../../../Assets/Icons/arrow-left-white.svg");
}

.testimonial2_sc.black .swiper-button-next:after {
  background-image: url("../../../Assets/Icons/arrow-right-white.svg");
}

@media (orientation: portrait) {
  .testimonial2_sc.black {
    margin: 2.5rem 0;
  }

  .testimonial2_sc .grid_container {
    grid-template-columns: 1fr;
  }
  .testimonial2_sc .grid_container .logo_item {
    display: block;
  }
  .testimonial2_sc .grid_container .grid_item:nth-child(1) {
    text-align: left;
    padding: 0 1rem;
    margin-bottom: -1px;
    border-bottom-color: transparent;
  }

  .testimonial2_sc .grid_container .grid_item:nth-child(2) {
    padding: 1rem 1rem 5rem;
    grid-column: auto;
    border-top-color: transparent;
  }

  .testimonial2_sc p {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 2rem;
  }

  .testimonial2_sc .swiper-button-next,
  .testimonial2_sc .swiper-button-prev {
    top: auto !important;
    bottom: 1rem !important;
  }

  .testimonial2_sc .swiper-button-next {
    right: 0.75rem;
  }

  .testimonial2_sc .swiper-button-prev {
    right: 3rem;
  }
}
