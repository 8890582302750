.abt_slider_sc {
    border-radius: 0px 0px 80px 80px;
    background   : var(--black-1);
}

.abt_slider_sc .grid_container {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    gap                  : 1.875rem;
    align-items          : center;
}

.abt_slider_sc .grid_item>* {
    color: var(--white);
}

.abt_slider_sc .grid_item:nth-child(2) {
    margin-top: -65px;
}

.abt_slider_sc .grid_item span {
    font-size  : 1.5rem;
    line-height: 2rem;
}

.abt_slider_sc .grid_item h5 {
    font-weight: 600;
    font-size  : 2.5rem;
    line-height: 3.375rem;
    margin     : 4rem 0 1.875rem;
}

.abt_slider_sc .grid_item p {
    font-size  : 1.25rem;
    line-height: 1.875rem;
}

/* .abt_slider_sc .swiper-pagination {
    width: fit-content;
    bottom    : 5rem;
    left      : 51%;
} */

.abt_slider_sc .swiper-pagination-bullet {
    background: var(--white) !important;
}

.abt_slider_sc .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--purple) !important;
}

@media (orientation: portrait) {
    .abt_slider_sc {
        border-radius: 0 0 20px 20px;
    }

    .abt_slider_sc.py_10 {
        padding-bottom: 3.75rem
    }

    .abt_slider_sc .grid_container {
        grid-template-columns: 1fr;
    }

    .abt_slider_sc .grid_item:nth-child(2) {
        margin-top: 0;
        padding-bottom: 2.5rem;
    }

    .abt_slider_sc .grid_item span {
        font-size  : 1rem;
        line-height: 1.375rem;
    }

    .abt_slider_sc .grid_item h5 {
        font-size  : 1.5rem;
        line-height: 1.875rem;
        margin     : 2.5rem 0 1.5rem;
    }

    .abt_slider_sc .grid_item p {
        font-size: 1rem;
        line-height: 1.625rem;
        padding-bottom: 2rem;
    }

    .abt_slider_sc .swiper-pagination {
        bottom: 0;
        left: 0;
        width: 100%;
    }
}