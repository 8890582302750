.services_sc {
    background-color: var(--black-1);
    padding: 7.5rem 0;
    border-radius: 0 0 5rem 5rem;
    position: relative;
}

/* swiper */
.services_sc .swiper-pagination {
    bottom: 4rem;
}

@media (orientation: portrait) {
    .services_sc .swiper-pagination {
        bottom: 3.25rem;
    }
    .services_sc {
        border-radius: 0 0 1.25rem 1.25rem;
        padding: 3.75rem 0 5.3125rem;
    }
}