.srv_number {
    display         : inline-block;
    background-color: var(--purple);
    border-radius   : 10px;
    position        : relative;
    padding         : 4px;
    width           : 7.375rem;
    height          : 6.25rem;
}

.srv_number .inner {
    width           : 100%;
    height          : 100%;
    background-color: #eaeaea;
    border-radius   : 6px;
    display         : flex;
    justify-content : center;
    align-items     : center;
}

.srv_number .inner h5 {
    font-weight: 500;
    font-size  : 3rem;
    line-height: 100%;
    color      : var(--purple);
}

.srv_number .line {
    position        : absolute;
    background-color: #eaeaea;
}

.srv_number .line.top,
.srv_number .line.bottom {
    height   : 4px;
    width    : 58%;
    left     : 50%;
    transform: translateX(-50%);
}

.srv_number .line.right,
.srv_number .line.left {
    width    : 4px;
    height   : 50%;
    top      : 50%;
    transform: translateY(-50%);
}

.srv_number .line.top {
    top: 0;
}

.srv_number .line.right {
    right: 0;
}

.srv_number .line.bottom {
    bottom: 0;
}

.srv_number .line.left {
    left: 0;
}

@media (orientation: portrait) {
    .srv_number {
        width  : 4.375rem;
        height : 3.75rem;
        padding: 3px;
    }

    .srv_number .inner h5 {
        font-size: 2rem;
    }
}