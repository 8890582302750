.accordion_item {
    border-top   : 0.5px solid #000;
    border-bottom: 0.5px solid #000;
    padding      : 2.5rem 0;
    margin-top   : -1px;
}

.accordion_body {
    height    : 0;
    overflow  : hidden;
    transition: height .3s ease-in-out;
}

.accordion_item.open .accordion_body {
    height: initial;
}

.accordion_body_inner {
    padding-top: 2.5rem;
}

.accordion_body p {
    font-size  : 1.25rem;
    line-height: 150%;
}

.accordion_header h4 {
    position   : relative;
    font-size  : 2.5rem;
    font-weight: 500;
    line-height: 120%;


}

.accordion_header h4::after {
    position         : absolute;
    content          : '';
    right            : 0;
    top              : 50%;
    transform        : translateY(-50%);
    width            : 2rem;
    height           : 2rem;
    background-image : url('../../../../Assets/Icons/bx-plus.svg');
    background-repeat: no-repeat;
    background-size  : 2rem;
}

.accordion_item.open .accordion_header h4::after {
    background-image: url('../../../../Assets/Icons/bx-minus.svg');
}


@media (orientation: portrait) {
    .accordion_item {
        padding: 2rem 0;
    }

    .accordion_header h4 {
        font-size: 1.5rem;
    }

    .accordion_header h4::after {
        width          : 1.5rem;
        height         : 1.5rem;
        background-size: 1.5rem;
    }

    .accordion_body_inner {
        padding-top: 1.75rem;
    }

    .accordion_body p {
        font-size: 1rem;
    }

}