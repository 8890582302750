.footer {
  background-color: var(--black-1);
}

.footer_top {
  width              : 100%;
  height             : 35rem;
  background-image   : url(../../../Assets/Images/footer_top.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  overflow           : hidden;
  border-radius      : 0px 0px 5rem 5rem;

}

.footer_top_content {
  height     : 35rem;
  display    : flex;
  align-items: center;
}

.footer_top_content_inner {
  width: 100%;
  display        : flex;
  flex-wrap      : wrap;
  justify-content: space-between;
  align-items    : flex-end;
}

.footer_top_content h1 {
  width      : 65%;
  color      : var(--white);
  font-size  : 3.75rem;
  font-style : normal;
  font-weight: 500;
  line-height: 124%;
}

.contact_arrow,
.contact_arrow>div {
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
}

.contact_arrow {
  gap: 2.25rem;
}

.contact_arrow p {
  color      : var(--white);
  text-align : right;
  font-size  : 2.5rem;
  font-style : normal;
  font-weight: 500;
  line-height: normal;
}

.footer_content .grid_container {
  display              : grid;
  grid-template-columns: repeat(4, 1fr);
  gap                  : 6.25rem;
}

.footer_content p,
.footer_content a,
.footer_content h5 {
  display: block;
  color  : var(--white);
}

.footer_content h5 {
  font-size  : 1.125rem;
  font-weight: 600;
}

.footer_content .grid_item>*:not(:last-child) {
  margin-bottom: 1.5rem;
}

.footer_content .grid_item:nth-child(1) img {
  width: 4.5rem;
}

.footer_content .grid_item:nth-child(1) p,
.footer_content .grid_item:nth-child(5) p {
  font-size: .875rem;
}

.footer_content.bottom p {
  text-align: center;
}

.footer_content .grid_item:last-child {
  display: none;
}


@media (orientation: portrait) {
  .footer_top {
    width        : 100%;
    height       : auto;
    padding      : 76px 0 85px;
    border-radius: 0px 0px 1.25rem 1.25rem;
  }

  .footer_top_content {
    height : auto;
    row-gap: 4rem;
  }
  .footer_top_content_inner {
    gap: 2rem;
}

  .footer_top_content h1 {
    width      : 100%;
    font-size  : 2.5rem;
    line-height: 110%;
  }

  .contact_arrow {
    padding-top: 0;
  }

  .contact_arrow p {
    font-size    : 2rem;
    padding-right: 1rem;
  }

  .footer_content .grid_container {
    grid-template-columns: repeat(2, 1fr);
    column-gap           : 2rem;
    row-gap              : 3.75rem;
  }

  .footer_content .grid_item:nth-child(1)>*:not(:last-child) {
    margin-bottom: 0;
  }

  .footer_content .grid_item:nth-child(1) {
    grid-column: 1 / 3;
  }

  .footer_content .grid_item:last-child {
    display: block;
  }

  .footer_content .grid_item:nth-child(1)>p {
    display: none;
  }
}