.nm_articles{
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
}
.nm_articles_small{
    display: none;
}
@media (orientation: portrait) {
    .nm_articles{
        display: none;
 
    }
    .nm_articles_small{
        padding-top: 2.5rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.875rem;
    }
 
    
}