.impact_card {
    background-color: var(--white);
    border-radius   : 1.875rem;
    padding         : 7.5rem 1.5rem 3.75rem;
}

.impact_card .number {
    display      : flex;
    align-items  : flex-end;
    margin-bottom: 1.5rem;
}

.impact_card .number h1 {
    font-weight: 500;
    font-size  : 8.75rem;
    line-height: 8.75rem;
    color      : var(--black-2);
}

.impact_card .number span {
    font-weight: 500;
    font-size  : 3.75rem;
    line-height: 3.75rem;
    color      : var(--black-2);
}

.impact_card h5 {
    font-weight: 500;
    font-size  : 1.5rem;
    line-height: 120%;
    max-width: 11.875rem;
}

.impact_card p {
    font-weight: 500;
}

@media (orientation: portrait) {
    .impact_card {
        padding: 2rem 1rem 2.25rem;
    }

    .impact_card .number {
        margin-bottom: 1rem;
    }

    .impact_card .number h1 {
        font-size  : 3.75rem;
        line-height: 3.75rem;
    }

    .impact_card .number span {
        font-size  : 1.75rem;
        line-height: 1.75rem;
    }

    .impact_card h5 {
        font-size: 1rem;
    }

    .impact_card p {
        font-weight: 400;
    }
}