.srv_video_sc .video {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

.srv_video_sc .video>img {
    width: 100%;
}

.srv_video_sc .video .play_btn {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

.srv_video_sc .video button img {
    width: 7.5rem;
}



@media (orientation: portrait) {
    .srv_video_sc .video>img {
        height: 342px;
        object-fit: cover;
        object-position: center;
    }

    .srv_video_sc .video button img {
        width: 5rem;
    }
}