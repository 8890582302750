.magazineParent {
  width: 100%;
  display: flex;
  gap: 30px;
}

.magazineParent img {
  height: 780px;
  border-radius: 33.43px;
}

@media (orientation: portrait) {
  .magazineParent {
    flex-direction: column;
    gap: 24px;
  }

  .magazineParent img {
    height: 400px;
    border-radius: 23.43px;
  }
}
