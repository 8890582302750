.image_gallery{
    padding-top: 200px;
    padding-bottom: 200px;
}
.image_gallery h1{
    color: #2B2F2C;
    font-size: 7.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; 
    padding-bottom: 2rem;
}

.image_tag{
    border-radius: 1000px;
    border: 1px solid #222;
    padding: 12px 1.5rem;
    text-align: center;
    cursor: pointer;
    display: inline-flex;
    margin:10px;

}
.image_tags p{
    color: #222;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; 
   
}
.g_images{
    display: block;
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
    
}
.g_image{
    width: 380px;
    height: 280px;
}
.g_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.g_btn{
    text-align: center;
    padding-top: 3.75rem;

}
.g_btn button{
    border-radius: 8px;
    border: 1px solid #1C1C1C;
    padding: 16px 24px;
}
.g_btn button > span{
    color: #1C1C1C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

}
/*  */
.image_tag.active {
    border: 1px solid #222;
    background: #222;
    color: #fff; 

  }
  .image_tag .active {
    color: #fff;
  }
.image_tag_select{
    display: none;
}
.g_images_small{
    display: none;
}
.small_btn{
  display: none;
}
.large_btn{
    display: block;
  }
@media (orientation: portrait) {

    .image_tags{
        display: none;
    }
    .image_gallery {
        padding-top: 5.5rem;
    }
    .g_images{
        display: none;
    }
    .g_images_small{
        display: block;
        padding-top: 2.5rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.875rem;
    }
    .image_gallery h1{
        color: #2B2F2C;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 100%;
    }
    .g_image{
        /* max-width: 343px; */
        width: 100%;
        height: 220px;
        margin: 0 auto;
    }
    .image_tag_select {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        
      }  
       
      .select-box {
        padding: 10px 15px;
        margin: 5px;
        border-radius: 100px;
        border: 1px solid #222;
        cursor: pointer;
        color: #222;
        font-size: 16px;
        font-weight: 500;
        appearance: none;
        width: 100%;
      }
       .select-box option{
        color: #222;
        font-size: 16px;
        font-weight: 500;
        border: none;
       }
      .image_tag_select svg{
        position: absolute;
        right: 6%;
        top: 32%;
        width: 1.25rem;
        height: 1.25rem;
      }
      .small_btn{
        display: block;
      }
      .large_btn{
        display: none;
      }
}