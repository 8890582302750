.home_banner {
  width: 100%;
  height: 100vh;
  background-image: url(../../../../Assets/Images//homeBg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;
}

.home_banner .container {
  height: 100%;
}

.home_banner .banner_inner {
  width: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_banner .large_title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -40px;
}

.home_banner .large_title h2 {
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 48.6px;
  color: var(--white);
  background-color: #2B2F2C66;
  backdrop-filter: blur(100px);
  border-radius: 12px;
  padding: 16px;
}

/* .home_banner .large_title h2:nth-child(2) {
    background             : -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) -10%, rgba(255, 255, 255, 1) 60%);
    background-clip        : text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left            : 645px;
} */

.home_banner .list span {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white);
  opacity: 0.6000000238418579;
  margin-right: 0.5rem;
}

.home_banner .text_button {
  display: flex;
  justify-content: space-between;
  margin-top: 3.125rem;
}

.home_banner .text_button > p {
  max-width: 36.5625rem;
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--white);
  line-height: 2rem;
}

@media (orientation: portrait) {
  .home_banner {
    height: 37.5rem;
  }

  .home_banner .large_title h2 {
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--white);
    background-color: var(--black-1);
    border-radius: 12px;
  }

  .text1 {
    margin: 0 0px;
    padding: 16px 8px;
  }

  .text2 {
    margin: 0 0px;
    padding: 16px 16px;
  }

  /* .home_banner .large_title h2 {
        font-size: 3.75rem;
        line-height: 4rem;
        letter-spacing: -2.4px;
    } */
  /* .home_banner .large_title h1:nth-child(2) {
        margin-left: 9.375rem;
    } */

  /* .home_banner .list {
        margin-top: 2.1875rem;
    }

    .home_banner .list span {
        font-size: 1rem;
    }

    .home_banner .text_button{ 
        flex-direction: column;
        row-gap: 2.5rem;
    }

    .home_banner .text_button>p {
        font-size: 1rem;
        line-height: 1.375rem;
    } */
}
