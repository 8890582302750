.tstm_card {
    border-radius   : 1.875rem;
    background-color: var(--white);
    padding         : 1.5rem;
}

.tstm_card .img_box {
    width        : 3.75rem;
    height       : 3.75rem;
    border-radius: 50%;
    overflow     : hidden;
}

.tstm_card .img_box img {
    width          : inherit;
    height         : inherit;
    object-fit     : cover;
    object-position: center center;
}

.tstm_card p {
    height            : 9rem;
    font-size            : 1rem;
    line-height          : 150%;
    color                : var(--black-1);
    margin               : 2rem 0 3rem;
    overflow          : auto;
    /* -ms-overflow-style   : none;
    scrollbar-width      : none; */
}

.tstm_card p::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.tstm_card p::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.tstm_card p::-webkit-scrollbar-thumb {
    background: #888;
}

.tstm_card h5 {
    font-weight: 600;
    font-size  : 1rem;
}

.tstm_card span {
    font-size : 1rem;
    margin-top: .25rem;
    height: 70px;
}



@media (orientation: portrait) {
    .tstm_card {
        /* min-height: 25rem; */

    }
    .tstm_card p {
        height            : 10.5rem;
        -webkit-line-clamp: 7;
        margin            : 2.5rem 0 2.5rem;
    }
}