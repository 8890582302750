.featured_in h1 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    text-align: center;
    text-transform: uppercase;
}

.featued_logo {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.featued_logo img {
    max-width: 100%;
    /* height: 2rem; */
}



@media (orientation: portrait) {
    .featured_in h1 {
        font-size: 1rem;
        line-height: 1.375rem;
    }

    .featured_in .container {
        padding: 0;
    }
    .featured_in .container>h1 {
        padding: 0 1rem;
    }
    
}