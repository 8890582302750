.video_iframe_wrapper {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.video_iframe_wrapper iframe {
    width        : 100%;
    height       : 100%;
    margin-bottom: -.5rem;
}

@media screen and (orientation: portrait) {

    /* .video_iframe_wrapper {
        height: 200px;
    } */
}