.overflow_sc .height_only {
    height: 150px;
}

.overflow_sc .bg_black {
    background-color: var(--black-1);
    border-radius   : 0 0 80px 80px;
    position: relative;
}

.overflow_sc .swiper {
    overflow: visible !important;
}

.overflow_sc .swiper-slide {
    opacity            : 0 !important;
    visibility: hidden;
    transition-property: transform, opacity, visibility;
}

.overflow_sc .swiper-slide-active {
    opacity: 1 !important;
    visibility: visible;
}

.overflow_sc .swiper-pagination {
    bottom: 4rem !important
}

.overflow_sc .grid_container {
    display              : grid;
    grid-template-columns: 30.1875rem auto;
    gap                  : 8.25rem;
}

.overflow_sc .grid_container>*:nth-child(1) {
    margin-top: -150px;
}

.overflow_sc .grid_container>*:nth-child(2) {
    display        : flex;
    flex-direction : column;
    height         : 100%;
    justify-content: center;
}

.overflow_sc .grid_container>*:nth-child(2) .img_box {
    display: none;
}

.overflow_sc p.text {
    font-weight: 500;
    font-size  : 1.5rem;
    line-height: 2.625rem;
    margin     : 2.1875rem 0 3.4375rem;
}

.overflow_sc .img_box img {
    width: 100%;
}
.overflow_sc .grid_item .title_48{
    padding-top: 25px;
}

@media (orientation: portrait) {

    .overflow_sc .bg_black {
        padding      : 4rem 0 7rem;
        border-radius: 0 0 20px 20px;
    }

    .overflow_sc .grid_container {
        grid-template-columns: 1fr;
    }

    .overflow_sc .grid_container .grid_item:nth-child(1) {
        display: none;
    }

    .overflow_sc .grid_container>*:nth-child(2) .img_box {
        display: block;
    }

    .overflow_sc .height_only {
        height: 30px;
    }

    .overflow_sc p.text {
        font-weight: 400;
        font-size  : 1rem;
        line-height: 1.625rem;
        margin     : 2rem 0 2.5rem;
    }

    .overflow_sc .grid_container .grid_item:nth-child(2) .img_box {
        margin-bottom: 2.5rem;
    }
}