.success_story_sc {
  position: relative;
  padding-bottom: 200px;
}

@media (orientation: portrait) {
  .success_story_sc {
    margin: 3rem 0 4rem;
  }

  .ss_card .content {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
  }

  .ss_card .content h2 {
    padding: 0.625rem;
  }
  .ss_card .content h2 > span {
    font-size: 1.25rem;
    line-height: 1.625rem;
    -webkit-line-clamp: 2;
  }
  .success_story_sc {
    padding-bottom:0;
  }
}
