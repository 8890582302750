.service_card {
    border-radius: 1.875rem;
    padding      : 3.3125rem 1.5rem 3.75rem;
    overflow     : hidden;
    transition   : background-color .3s ease-in-out;
}

.service_card h1 {
    font-weight: 500;
    font-size  : 3.75rem;
    line-height: 3.75rem;

    margin-bottom: 7rem;
}

.service_card.white h1 {
    color: var(--white);
}

.service_card .content {
    transform : translateY(8.625rem);
    transition: transform .3s ease-in-out;
}

.service_card:hover .content {
    transform: translateY(0);
}

.service_card .content .head {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.service_card .content .head>*:nth-child(1) {
    flex : 0 0 auto;
    width: 11.375rem;
}

.service_card .content .head h3 {
    font-weight: 500;
    font-size  : 2rem;
    line-height: 120%;
}

.service_card.white .content .head h3 {
    color: var(--white);
}

.service_card .content .head img {
    width     : 2.5rem;
    visibility: hidden;
    transition: visibility .3s ease-in-out;
}

.service_card.white .content .head img {
    filter: invert(1);
}

.service_card:hover .content .head img {
    visibility: visible;
}

.service_card .content p {
    height            : 7.25rem;
    font-size         : 1rem;
    line-height       : 120%;
    border-top        : 1px solid var(--black-2);
    display           : -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    padding-top       : 1.25rem;
    margin-top        : 1.25rem;
    visibility        : hidden;
    transition        : visibility .3s ease-in-out;
}

.service_card.white .content p {
    color: var(--white);
    border-color: var(--white);
}

.service_card:hover .content p {
    visibility: visible;
}

@media (orientation: portrait) {
    .service_card h1 {
        margin-bottom: 6.25rem;
    }

    .service_card .content {
        transform: translateY(0);
    }

    .service_card .content .head img {
        width     : 2.5rem;
        visibility: visible;
        /* filter    : invert(1); */
    }

    .service_card .content p {
        height     : 7.25rem;
        font-size  : 1rem;
        line-height: 120%;
        /* color      : var(--white); */
        /* border-top : 1px solid var(--white); */
        padding-top: 1.25rem;
        margin-top : 1.25rem;
        visibility : visible;
    }
}