.home_about .grid_container {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    gap                  : 1.875rem;
}

.home_about .img_box {
    border-radius: 1.875rem;
    overflow     : hidden;
}

.home_about .img_box img {
    width: 100%;
}

.home_about p.text {
    font-size  : 1.5rem;
    line-height: 2rem;
    margin     : 2.5rem 0 1.875rem;
}

.home_about .grid_item:nth-child(1) .img_box {
    display: none;
}


@media (orientation: portrait) {
    .home_about .grid_container {
        grid-template-columns: 1fr;
    }

    .home_about .grid_item:nth-child(1) .img_box {
        display: block;
        margin-top: 2rem;
    }

    .home_about .grid_item:nth-child(2) {
        display: none;
    }

    .home_about p.text {
        font-size: 1rem;
        line-height: 1.625rem;
        margin: 2.5rem 0 2rem;
    }
}