.grid_1_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap                  : 1.875rem;
}

.grid_1_3 .grid_item:nth-child(2) {
    grid-column: 2 / span 2;
}



@media (orientation: portrait) {
    .grid_1_3 {
        grid-template-columns: 1fr;
        gap: 2.5rem;
    }

    .grid_1_3 .grid_item:nth-child(2) {
        grid-column: auto;
    }
    
}