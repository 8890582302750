.social_wrapper>a {
    display        : flex;
    align-items    : center;
    gap            : .75rem;
}

.social_wrapper>a>*:first-child {
    flex : 0 0 1.25rem;
    width: 1.25rem;
}

.social_wrapper>a svg {
    width    : 100%;
    height   : 100%;
}

.social_wrapper>a:not(:last-child) {
    margin-bottom: 1.5rem;
}


