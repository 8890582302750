.impat_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .75rem;
}

@media (orientation: portrait) {
    .impat_cards {
        grid-template-columns: repeat(2, 1fr);
        gap: .75rem;
    }
}