.ss_card {
    position     : relative;
    border-radius: 1.25rem;
    border       : 1px solid var(--black-1);
    overflow     : hidden;
    width: 585px;
    height: 400px;
}

.ss_card:hover {
    cursor: pointer;
}

.ss_card .thum_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ss_card .content {
    position: absolute;
    left    : 1.5rem;
    right   : 1.5rem;
    bottom  : 1.5rem;
}

.ss_card .content>div {
    display              : grid;
    grid-template-columns: auto 3.75rem;
    gap                  : 1.5rem;
    align-items          : center;
}

.ss_card .content h2 {
    background-color  : var(--white);
    padding           : 1.125rem;
    border-radius: .625rem;
}
.ss_card .content h2>span {
    font-weight       : 600;
    font-size         : 1.75rem;
    line-height       : 1.75rem;
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

@media (orientation: portrait) {
    .ss_card {
        width: 100%;
        height: 222px;
        margin: 0 auto;
    }
    
}