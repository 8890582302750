.contact_page {
    padding: 13.75rem 0 12.5rem;
}

.contact_page h1 {
    font-weight: 500;
    font-size  : 7.5rem;
    line-height: 100%;
    max-width  : 49.375rem;
}

.contact_page .form_wrapper {
    border-radius   : 1.875rem;
    margin-top      : 7.5rem;
    background-color: var(--black-1);
    padding         : 6.375rem;
}

.contact_page .form_wrapper form>*:not(:last-child) {
    margin-bottom: 2.5rem;
}

.contact_page .form_wrapper .grid_2 {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    gap                  : 1.875rem;
}

.contact_page .form_wrapper label,
.contact_page .form_wrapper input,
.contact_page .form_wrapper textarea {
    color: var(--white);
}

.contact_page .form_wrapper .label {
    display       : block;
    font-weight   : 400;
    font-size     : 1.5rem;
    line-height   : 100%;
    text-transform: capitalize;
    margin-bottom : 1.25rem;
}

.contact_page .form_wrapper input:not([type="checkbox"]),
.contact_page .form_wrapper textarea {
    width           : 100%;
    font-size       : 1.125rem;
    line-height     : 150%;
    border-radius   : 8px;
    border          : 0.5px solid #FFF;
    background-color: transparent;
    padding         : .75rem 1rem;
}



.contact_page .form_wrapper input[type="checkbox"] {
    appearance: none;
}

.contact_page .checkboxs_wrapper label {
    display    : flex;
    align-items: center;
    gap        : 1.5rem;
}

.contact_page .checkboxs_wrapper label .outer {
    width      : 40px;
    height     : 40px;
    flex-shrink: 0;
    border     : 0.5px solid var(--white);
    padding    : .625rem;
}

.contact_page .checkboxs_wrapper label .inner {
    width           : 100%;
    height          : 100%;
    background-color: var(--white);
    visibility: hidden;
}

.contact_page .checkboxs_wrapper input:checked~label .inner {
    visibility: visible;
}


.submit_btn_wrap button {
    width           : 100%;
    font-size       : 1.75rem;
    line-height     : 1.75rem;
    text-transform  : capitalize;
    text-align      : center;
    border-radius   : .5rem;
    background-color: var(--white);
    padding         : 2.125rem;
}
.submit_btn_wrap button span{
    color: var(--black-1);
    font-weight   : 400;
}


@media (orientation: portrait) {
    .contact_page {
        padding: 7.5rem 0 5rem;
    }
    .contact_page h1 {
        font-size: 2.5rem;
        max-width: 49.375rem;
    }

    .contact_page .form_wrapper {
        margin-top: 3.75rem;
        padding: 2.5rem 1rem;
    }
    .contact_page .form_wrapper .grid_2 {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .contact_page .form_wrapper .label {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .contact_page .form_wrapper input:not([type="checkbox"]), .contact_page .form_wrapper textarea {
        font-size: 1rem;
        padding: .625rem .75rem;
    }

    .contact_page .checkboxs_wrapper label .outer {
        width: 1.5rem;
        height: 1.5rem;
        padding: .375rem;
    }

    .submit_btn_wrap button {
        font-size: 1rem;
        line-height: 1rem;
        padding: 1.25rem;
    }
 }