@import url(./Assets/Fonts/GeneralSans.css);

:root {
  --black-1: #1C1C1C;
  --black-2: #2B2F2C;
  --purple : #680ADC;
}

html,
body {
  font-family     : 'General Sans';
  font-weight     : 400;
  font-size       : 16px;
  line-height     : 22px;
  color           : var(--black-2);
  background-color: #EAEAEA;
}

/* >>== utility start ==<< */
.w_100 {
  width: 100%;
}

/* padding */
.pt_10,
.py_10 {
  padding-top: 100px;
}

.pb_10,
.py_10 {
  padding-bottom: 100px;
}

.pt_8,
.py_8 {
  padding-top: 80px;
}

.pb_8,
.py_8 {
  padding-bottom: 80px;
}

.pt_7,
.py_7 {
  padding-top: 70px;
}

.pb_7,
.py_7 {
  padding-bottom: 70px;
}

.pt_6,
.py_6 {
  padding-top: 60px;
}

.pb_6,
.py_6 {
  padding-bottom: 60px;
}

.pt_5,
.py_5 {
  padding-top: 50px;
}

.pb_5,
.py_5 {
  padding-bottom: 50px;
}

.pt_4,
.py_4 {
  padding-top: 40px;
}

.pb_4,
.py_4 {
  padding-bottom: 40px;
}

/* margin */
.mt_10,
.my_10 {
  margin-top: 100px;
}

.mb_10,
.my_10 {
  margin-bottom: 100px;
}

.mt_8,
.my_8 {
  margin-top: 80px;
}

.mb_8,
.my_8 {
  margin-bottom: 80px;
}

.mt_7,
.my_7 {
  margin-top: 70px;
}

.mb_7,
.my_7 {
  margin-bottom: 70px;
}

.mt_6,
.my_6 {
  margin-top: 60px;
}

.mb_6,
.my_6 {
  margin-bottom: 60px;
}

.mt_5,
.my_5 {
  margin-top: 50px;
}

.mb_5,
.my_5 {
  margin-bottom: 50px;
}

.mt_4,
.my_4 {
  margin-top: 40px;
}

.mb_4,
.my_4 {
  margin-bottom: 40px;
}

/* font color */
.fc_white {
  color: var(--white);
}

/* >>== utility end ==<< */


/* >>== container start ==<< */
.container_fluid {
  width: 100%;
}

.container {
  margin: 0 auto;
}

/* >>== container end ==<< */


/* >>== titles start ==<< */
.sch_wrapper {
  position: relative;
}

.sch_wrapper.with_flex {
  display        : flex;
  justify-content: space-between;
  align-items    : flex-end;
}

.sch_wrapper.with_flex>*:nth-child(1) {
  flex : 0 0 auto;
  width: 742px;
}

.title_60 {
  font-weight: 600;
  font-size  : 60px;
  line-height: 70px;
}

.title_60.center,
.title_48.center {
  text-align: center;
}

.title_48 {
  font-weight: 600;
  font-size  : 3rem;
  line-height: 120%;
}

.title_24 {
  font-weight  : 500;
  font-size    : 1.5rem;
  line-height  : 1.5rem;
  margin-bottom: 1.875rem;
}

/* >>== titles end ==<< */


/* >>== buttons start ==<< */
.btn_large {
  position        : relative;
  width           : 277px;
  display         : flex;
  justify-content : space-between;
  align-items     : flex-end;
  background-color: var(--purple);
  border-radius   : 8px;
  padding         : 12px;
  overflow        : hidden;
}

.btn_large::before {
  content         : '';
  background-color: var(--black);
  position        : absolute;
  bottom          : 0;
  left            : 0;
  width           : 100%;
  height          : 0;
  transition      : height .2s ease-in-out
}

.btn_large:hover::before {
  height: 100%;
}

.btn_large>div {
  max-width     : 7.8125rem;
  display       : flex;
  flex-direction: column;
  z-index       : 1;
}

.btn_large span {
  font-weight: 400;
  font-size  : 12px;
  line-height: 12px;
  color      : var(--white);
}

.btn_large p {
  font-weight: 600;
  font-size  : 20px;
  line-height: 24px;
  color      : var(--white);
  margin-top : 12px;
}

.btn_large img {
  width    : 24px;
  transform: rotate(-45deg);
}

.btn_large.bg_white {
  background-color: var(--white);
}

.btn_large.bg_white span,
.btn_large.bg_white p {
  color     : var(--purple);
  transition: color .2s ease-in-out;
}

.btn_large.bg_white:hover span,
.btn_large.bg_white:hover p {
  color: var(--white);
}

.btn_large.bg_white img {
  transition: filter .2s ease-in-out;
}

.btn_large.bg_white:hover img {
  filter: grayscale(1) invert(1) contrast(1.4);
}

/* >>== buttons end ==<< */


/* >>== swiper pagination & navigation start ==<< */
.swiper-slide:hover {
  cursor: pointer;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2 !important;
}

.white_pagination .swiper-pagination {
  display: none;
}

.swiper-pagination-bullet {
  width        : 12px !important;
  height       : 12px !important;
  /* background   : #A5A5A5 !important; */
  background   : var(--white) !important;
  border-radius: 50% !important;
  opacity      : 1 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width        : 24px !important;
  height       : 24px !important;
  background: var(--purple) !important;
}

.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px !important;
}


.swiper-button-next,
.swiper-button-prev {
  width  : 2.5rem !important;
  height : 2.5rem !important;
  z-index: 2 !important;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content          : '' !important;
  background-size  : 2.5rem 2.5rem;
  background-repeat: no-repeat;
  width            : 2.5rem;
  height           : 2.5rem;
}

.swiper-button-next:after {
  background-image: url(./Assets/Icons/arrow-right-purple.svg);

}

.swiper-button-prev:after {
  background-image: url(./Assets/Icons/arrow-left-purple.svg);

}

.right_bottom .swiper-button-next,
.right_bottom .swiper-button-prev {
  top   : auto !important;
  bottom: 0 !important;
}

.right_bottom .swiper-button-next {
  right: 0;
}


.right_bottom .swiper-button-prev {
  left : auto;
  right: 4rem;
}

/* >>== swiper pagination & navigation end ==<< */


/* >>== responsive min width start ==<< */
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

/* >>== responsive min width end ==<< */

/* >>== responsive max width start ==<< */
@media (max-width: 1199.98px) {
  .container_fluid {
    padding: 0 16px;
  }
}


@media (orientation: portrait) {

  /* padding */
  .pt_10,
  .py_10 {
    padding-top: 40px;
  }

  .pb_10,
  .py_10 {
    padding-bottom: 40px;
  }

  .pt_8,
  .py_8 {
    padding-top: 40px;
  }

  .pb_8,
  .py_8 {
    padding-bottom: 40px;
  }

  .pt_7,
  .py_7 {
    padding-top: 40px;
  }

  .pb_7,
  .py_7 {
    padding-bottom: 40px;
  }

  .pt_5,
  .py_5 {
    padding-top: 25px;
  }

  .pb_5,
  .py_5 {
    padding-bottom: 25px;
  }

  .pt_6,
  .py_6 {
    padding-top: 30px;
  }

  .pb_6,
  .py_6 {
    padding-bottom: 30px;
  }

  /* margin */
  .mt_10,
  .my_10 {
    margin-top: 40px;
  }

  .mb_10,
  .my_10 {
    margin-bottom: 40px;
  }

  .mt_8,
  .my_8 {
    margin-top: 40px;
  }

  .mb_8,
  .my_8 {
    margin-bottom: 40px;
  }

  .mt_7,
  .my_7 {
    margin-top: 40px;
  }

  .mb_7,
  .my_7 {
    margin-bottom: 40px;
  }

  .mt_6,
  .my_6 {
    margin-top: 30px;
  }

  .mb_6,
  .my_6 {
    margin-bottom: 30px;
  }


  .sch_wrapper.with_flex>*:nth-child(1) {
    width: 100%;
  }

  .title_60 {
    font-size  : 2.5rem;
    line-height: 3rem;
    max-width: 18rem;

  }

  .title_48 {
    font-size: 2.5rem;
  }

  .title_24 {
    font-size    : 1.25rem;
    line-height  : 1.625rem;
    margin-bottom: 1.25rem;
  }

  .btn_large {
    width  : 100%;
    padding: 12px;
  }


  /* navigation */
  .swiper-button-next:after,
  .swiper-button-prev:after {
    content        : '' !important;
    background-size: 1.5rem 1.5rem;
    width          : 1.5rem;
    height         : 1.5rem;
  }
  .right_bottom .swiper-button-prev {
    right: 3rem;
  }
  /* .right_bottom .swiper-button-prev,
  .right_bottom .swiper-button-next {
    display: none;
  } */
  

  /* pagination */
  /* .white_pagination .swiper-pagination {
    display: block;
  } */

  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px !important;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 18px !important;
    height: 18px !important;
    background: var(--purple) !important;
}

  /* .swiper-pagination-bullet {
    width: 44px !important;
  } */

  .white_pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--black) !important;
  }


  /* .white_pagination .swiper-pagination {   
    display: block;
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom,8px); 
    top: var(--swiper-pagination-top,auto);
    left: 0;
    width: 100px;
    height: 20px;
    overflow-x: scroll;
} */



}



/* >>== responsive max width end ==<< */