.nm_success_gallery {
  padding-top: 200px;
}

.nm_success_gallery h1 {
  color: #2b2f2c;
  font-size: 7.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding-bottom: 2rem;
}

.nm_success_story {
  padding-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.125rem;
}
.nm_success_story_small {
  display: none;
}
@media (orientation: portrait) {
  .nm_success_gallery {
    padding-top: 5.5rem;
  }
  .nm_success_gallery h1{
    color: #2B2F2C;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 100%;
}

  .nm_success_story {
    display: none;
  }
  .nm_success_story_small {
    padding-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.125rem;
  }
}
