.video_sc .video {
    position: relative;
    border-radius: 1.25rem;
    overflow: hidden;
}

.video_sc .video>img {
    width: 100%;
}

.video_sc .video .play_btn {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

.video_sc .video button img {
    width: 7.5rem;
}


@media (orientation: portrait) {
    .video_sc .video {
        height: 343px;
    } 
    .video_sc .video>img {
        width: 100%;
        height: inherit;
        object-fit: cover;
        object-position: center;
    }

    .video_sc .video button img {
        width: 5rem;
    }
}