.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 0;
  z-index: 3;
}

.navbar.bg_white {
  background-color: var(--white);
}

.navbar .smallnav {
  display: none;
}

.navbar .logo_wrapper {
  flex: 0 0 auto;
  width: 3.75rem;
}

.navbar .logo_wrapper img {
  width: 100%;
}

.navbar .logo_wrapper img.logo_white {
  display: none;
}

.navbar.transparent:not(.bg_white) .logo_wrapper img.logo_white {
  display: block;
}

.navbar.transparent:not(.bg_white) .logo_wrapper img.logo_black {
  display: none;
}

.navbar .flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar nav a {
  position: relative;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: var(--black);
  text-transform: capitalize;
  padding: 0.8rem 1.2rem;
}

.navbar.transparent:not(.bg_white) nav a {
  color: var(--white);
}


.navbar nav a.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--black);
}

/* Change color for transparent navbar */
.navbar.transparent:not(.bg_white) nav a.active::before {
  background-color: var(--white);
}

/* Override styles for the dropdown-trigger link */
.navbar nav a.dropdown-trigger.active::before {
  content: none; /* This removes the underline for the dropdown trigger */
}
/* .navbar nav a:not(:last-child) {
    margin-right: 2.5rem;
} */

.navbar nav .nav_cont_btn {
  display: none;
}

.navbar .nav_cont_btn {
  display: flex;
  background-color: var(--black);
  border-radius: 8px;
  padding: 0.875rem 1.5rem;
}

.navbar.transparent:not(.bg_white) .nav_cont_btn {
  background-color: var(--white);
}

.navbar .nav_cont_btn span {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: var(--white);
  text-transform: capitalize;
}

.navbar.transparent:not(.bg_white) .nav_cont_btn span {
  color: var(--black);
}

.btn_menu_toggle {
  display: none;
}

.navbar.transparent:not(.bg_white) .btn_menu_toggle img {
  filter: invert(1);
}


/* Dropdown container */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

/* Trigger for the dropdown */
.custom-dropdown .dropdown-trigger {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

/* Dropdown menu */
.custom-dropdown .dropdown-menu {
  position: absolute;
  margin-top: 10px;
  background-color: white;
  /* border-bottom: 1px solid #ccc; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  z-index: 100;
  padding: 0px 0;
  border-radius: 12px;
  top: 100%;
  left: 16%;
  /* right: -10%; */
  width: 18vh;
}

/* Dropdown item */
.custom-dropdown .dropdown-item {
  padding: 10px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 14px !important;
  font-weight: 500;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Hover effect on dropdown item */
.custom-dropdown .dropdown-item:hover {
  background-color: #2B2F2C;
  color: #ffffff; /* Change this to your preferred hover background color */
}
.custom-dropdown .dropdown-item:nth-child(1) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.custom-dropdown .dropdown-item:nth-last-child(1) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: none;
}


@media (orientation: portrait) {
  .navbar {
    padding: 1rem 0;
  }

  .navbar.show {
    background-color: var(--white);
  }

  .navbar .largenav {
    display: none;
  }

  .navbar .smallnav {
    display: block;
  }

  .navbar .logo_wrapper {
    width: 2.5rem;
  }

  .btn_menu_toggle,
  .navbar nav .nav_cont_btn {
    display: block;
  }

  .navbar .flex_container > div {
    display: none;
  }

  .navbar nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    padding: 1rem;
    visibility: hidden;
  }

  .navbar nav.show {
    visibility: visible;
  }

  .navbar nav a {
    display: block;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: var(--black);
    padding: 0.8rem 0;
  }

  .navbar nav a:last-child {
    margin-top: 2rem;
  }

  /* .navbar nav a:not(:last-child) {
        margin-right : 0;
        margin-bottom: 2rem;
    } */

  .navbar nav .nav_cont_btn {
    display: inline-block;
    background-color: var(--purple);
    line-height: 0;
    border-radius: 8px;
  }

  .navbar nav .nav_cont_btn span {
    color: var(--white);
  }
}
