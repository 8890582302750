.image-gallery {
  display: flex;
  gap: 30px;
  width: 100%;
  height: 700px;
  margin-top: 60px;
  margin-bottom: 100px;
}

.leftSideImage {
  flex: 1;
}

.rightSideImage {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.topSideImage {
  display: flex;
  gap: 30px;
  height: 335px;
  margin-bottom: 30px;
}

.bottomSideImage {
  height: 335px;
}

.image-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

@media (orientation: portrait) {
  .image-gallery {
    flex-direction: column;
    height: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .leftSideImage,
  .rightSideImage {
    object-fit: left;
    width: 100%;
    height: 400px;
  }

  .topSideImage {
    flex-direction: column;
    height: auto;
  }

  .bottomSideImage {
    height: auto;
  }

  .image-item img {
    width: 100%;
    height: 400px;
    object-position: right;
  }
}
