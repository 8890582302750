.about_page {
    padding-top: 16.25rem;
}

.abt_page_title {
    font-size  : 5.5rem;
    line-height: 7.5rem;
}
.abt_page_title.chnage_text {
    font-size  : 3.5rem;
    line-height: 7.5rem;
}

.abt_page_title span {
    color: var(--purple);
}

.abt_page_title:nth-child(2) {
    display: flex;
    gap: 1.5rem;
}

.abt_page_center_text .container {
    max-width: 49.375rem;
}

.abt_page_center_text p {
    font-size  : 1.5rem;
    line-height: 150%;
}

.title_text_sc .grid_container {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    gap                  : 1.875rem
}

.title_text_sc h2 {
    font-weight: 600;
    font-size  : 2.5rem;
    line-height: 2.5rem;
    max-width  : 34.375rem;
}

.title_text_sc p {
    font-weight: 500;
    font-size  : 1.25rem;
    line-height: 150%;
}

.title_text_sc p:not(:last-child) {
    margin-bottom: 3rem;
}


.marque_sc>*:not(:last-child) {
    margin-bottom: 6.25rem;
}
.marque_sc{
    width: 100%;
    overflow: hidden;
}
.marque_sc div {
    display: flex;
    gap    : 100px;
}

.marque_sc div.one {
    margin-left: -580px;
}

.marque_sc div.two {
    margin-left: -280px;
}

/* .marque_sc div.two {
    transform: translateX(-300px);
} */

.marque_sc div p {
    position   : relative;
    font-weight: 600;
    font-size  : 120px;
    line-height: 100%;
    white-space: nowrap;
}

.marque_sc div p:not(:last-child):after {
    position        : absolute;
    content         : '';
    width           : 3.25rem;
    height          : 4px;
    background-color: var(--black-1);
    right           : -6.5rem;
    top             : 50%;
    transform       : translate(-50%);
}


@media (orientation: portrait) {
    .about_page {
        padding-top: 7.5rem;
    }

    .abt_page_title {
        font-size  : 2rem;
        line-height: 2.5rem;
    }

    .abt_page_title.chnage_text {
        font-size  : 1.2rem;
        line-height: 1.8rem;
    }

    .abt_page_title:nth-child(2) {
        gap: .75rem;
    }

    .abt_page_center_text.py_10 {
        padding-top: 0;
    }

    .abt_page_center_text p {
        font-size  : 1rem;
        line-height: 150%;
    }

    .about_page span{
        font-size: 24px;
    }
   

    .title_text_sc .grid_container {
        grid-template-columns: 1fr;
        gap                  : 2.5rem;
    }

    .title_text_sc h2 {
        font-size  : 2rem;
        line-height: 2rem;
    }

    .title_text_sc p {
        font-size  : 1rem;
    }

    .title_text_sc p:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    .marque_sc>*:not(:last-child) {
        margin-bottom: 2.5rem;
    }
    
    .marque_sc div p {
        font-size: 24px;
        white-space: wrap;
    }
    .marque_sc marquee>div {
        display: flex;
        gap    : 80px;
    }
    
    .marque_sc marquee>div p {
        font-size  : 60px;
        line-height: 100%;
    }
    
    .marque_sc marquee>div p:not(:last-child):after {
        width           : 2.25rem;
        right           : -4.5rem;
    }
}