.srv_overview .grid_1_3 .grid_item:nth-child(2) {
    display              : grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5rem;
    column-gap: 1rem;
}

.svr_ovrv_card h4 {
    font-weight: 500;
    font-size  : 1.75rem;
    line-height: 120%;
    margin: 3.125rem 0 1.5rem;
}

@media (orientation: portrait) {
    .srv_overview .grid_1_3 .grid_item:nth-child(2) {
        grid-template-columns: 1fr;
        row-gap: 3.75rem;
    }

    .svr_ovrv_card h4 {
        font-size: 1.5rem;
        margin: 2rem 0 1.25rem;
    }
}