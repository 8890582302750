.programs {
    background-color: var(--black-1);
    border-radius   : 0px 0px 80px 80px;
}

.programs .title_48 {
    color: var(--white);
}

.programs .grid_container {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    gap                  : 1.875rem;
}

.programs .grid_container>* {
    background-color: var(--white);
    border-radius   : 1.875rem;
    padding         : 2.5rem 1.5rem 3.75rem;
}

.programs .svr_ovrv_card h4 {
    font-size: 1.5rem;
    margin   : 5.625rem 0 3.125rem;
}

.programs .srv_number {
    width : 4.5rem;
    height: 3.75rem;
}

.programs .srv_number .inner,
.programs .srv_number .line {
    background-color: var(--white);
}

.programs .srv_number .inner h5 {
    font-size: 2.25rem;
}


@media (orientation: portrait) {
    .programs {
        border-radius: 0px 0px 20px 20px;
    }
    .programs .grid_container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .programs .srv_number {
        width: 4.375rem;
        height: 3.75rem;
    }

    .programs .svr_ovrv_card h4 {
        font-size: 1.25rem;
        margin: 2.5rem 0 1.5rem;
    }
}