.dream_container {
  width: 100%;
  display: flex;
  border: 1px solid #2b2f2c33;
}

.left-side {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #2b2f2c33;
  gap: 16px;
  padding: 60px 0;
}

.left-side h2 {
  font-weight: 500;
  font-size: 44px;
  margin-bottom: 10px;
  color: #333;
}

.highlight {
  display: flex;
  align-items: center;
  background-color: rgba(104, 10, 220, 0.1);
  border-radius: 100px;
  padding: 24px 28px;
}

.highlight-text {
  font-weight: 500;
  font-size: 44px;
  color: #333;
}

.emoji {
  font-size: 22px;
  margin-left: 10px;
}

.right-side {
  width: 60%;
  padding: 60px 30px;
}

.dream-side {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.dream1 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 30px;
}

.dream2 {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  gap: 30px;
}

.dream1,
.dream2 p {
  font-weight: 500;
  font-size: 32px;
  line-height: 43.2px;
  color: #2b2f2c;
}

.dream_text2 {
  margin-top: -10px;
}

.passion {
  display: flex;
  gap: 100px;
}

.passion p {
  font-weight: 500;
  font-size: 24px;
  line-height: 32.4px;
  font-style: italic;
  color: #2b2f2c;
}

#hr{
  border-top:  1px solid #2b2f2c33;
  border-bottom:  1px solid #2b2f2c33;
  padding-top: 80px;
  padding-bottom: 80px;
}



@media (orientation: portrait) {

  .dream_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #2b2f2c33;
  }

  .left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #2b2f2c33;
    gap: 12px;
    padding: 40px 0;
  }

  .left-side h2 {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .highlight {
    display: flex;
    align-items: center;
    background-color: rgba(104, 10, 220, 0.1);
    border-radius: 100px;
    padding: 8px 16px;
  }
  
  .highlight-text {
    font-weight: 500;
    font-size: 24px;
    color: #333;
  }

  .right-side {
    width: 100%;
    padding: 40px 20px;
  }
  
  .dream-side {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .dream-side img {
    width: 16%;
  }
  
  .dream1 {
    display: flex;
    align-items: start;
    margin-bottom: 24px;
    gap: 20px;
  }
  
  .dream2 {
    display: flex;
    align-items: start;
    margin-bottom: 24px;
    gap: 20px;
  }
  
  .dream1,
  .dream2 p {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #2b2f2c;
    
  }
  
  .dream_text1, .dream_text2 {
    margin-top: -6px;
  }
  
  .passion {
    display: flex;
    gap: 70px;
  }
  
  .passion p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-style: italic;
    color: #2b2f2c;
  }
  
  
  .hr1 {
    border: 1px solid #2b2f2c33;
    margin-bottom: 40px;
  }
  
  .hr2 {
    margin-top: 40px;
    border: 1px solid #2b2f2c33;
  }
}