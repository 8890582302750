.exhib_card {
    border-radius: 20px;
    border       : 1px solid #1C1C1C;
    background   : #F9F9F9;
    overflow     : hidden;
}

.exhib_card .img_box,
.exhib_card .content,
.exhib_card a {
    padding: 1.5rem;
}

.exhib_card .img_box {
    min-height     : 22.5rem;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.exhib_card .img_box img {
    max-width: 100%;
}

.exhib_card .content {
    border-style: solid;
    border-color: var(--black-1);
    border-width: 1px 0 1px 0;
}

.exhib_card .content span,
.exhib_card .content p {
    font-weight: 400;
    font-size  : 1rem;
    line-height: normal;
}

.exhib_card .content span {
    color: var(--purple);
}

.exhib_card .content p {
    height            : 2.75rem;
    color             : #222;
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

.exhib_card .content h5 {
    font-size  : 1.5rem;
    font-weight: 600;
    line-height: normal;
    margin     : .75rem 0;
}

.exhib_card a {
    display        : flex;
    justify-content: space-between;
    line-height    : 0;
    transition     : background-color .3s ease-in-out;
}

.exhib_card a:hover {
    background-color: var(--purple);
}

.exhib_card a>span {
    font-size  : 1rem;
    font-weight: 500;
    line-height: normal;
    color      : #2B2F2C;
    transition : color .3s ease-in-out;
}

.exhib_card a:hover>span {
    color: var(--white);
}

.exhib_card a>img {
    width     : 1.5rem;
    height    : 1.5rem;
    transform : rotate(-45deg);
    transition: filter .3s ease-in-out;
}

.exhib_card a:hover>img {
    filter: invert(1);
}